<template>
  <div class="login-window">
    <el-dialog v-model="loginPanel" center @open="openLoginDialog" @close="closeLoginDialog" :show-close="false">
      <template #header>
        <span>微信登录</span>
        <span>登录后即可开始学习~</span>
      </template>
      <div class="wx-login-wrapper">
        <iframe
          sandbox="allow-scripts allow-top-navigation allow-same-origin"
          scrolling="no"
          width="300"
          height="400"
          frameBorder="0"
          allowTransparency="true"
          :src="wxLoginSrc"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const loginPanel = defineModel<boolean>();

const fullPath = ref<string>(route.fullPath); // 当前路由的完整路径

const wxLoginSrc = computed(() => {
  const APPID = 'wx81c04d3548b45abf'; // 测试号
  const STATE = 'PC_BROWSER'; // 环境
  const domain = document.location.origin; // 跳转域名
  const url = `${domain}/api/user/wxLogin?fullPath=${fullPath.value}`;
  const REDIRECT_URI = encodeURI(`https://www.datawhale.cn/api/user/wxLoginRedirect?url=${url}`); // encodeURI用来编码url
  // 用来生成微信登录二维码的url
  return `https://open.weixin.qq.com/connect/qrconnect?appid=${APPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=snsapi_login&state=${STATE}#wechat_redirect`;
});

// 判断打开该网页的是否是微信内置的浏览器
const isWXBrowser = () => {
  return String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) === 'micromessenger';
};
// 判断打开该网页的是手机端还是电脑端
const isMobile = () => {
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
};
// 打开登录弹窗触发事件
const openLoginDialog = () => {
  const _isWxBrowser = isWXBrowser();
  const _isMobile = isMobile();

  if (_isMobile && !_isWxBrowser) {
    ElMessage.warning('移动端请在微信内打开');
    loginPanel.value = false;
    return;
  }

  // 只有在外部浏览器并且不是手机端才会弹出登录框
  loginPanel.value = !_isWxBrowser;

  if (_isWxBrowser) {
    const APPID = 'wxfb1a601cdd5a45c5';
    const STATE = 'WECHAT_BROWSER';
    const domain = document.location.origin; // 跳转域名
    const url = `${domain}/api/user/wxLogin?fullPath=${fullPath.value}`;
    const REDIRECT_URI = encodeURI(`https://www.datawhale.cn/api/user/wxLoginRedirect?url=${url}`);
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=snsapi_userinfo&state=${STATE}#wechat_redirect`;
  }
};
// 关闭登录弹窗触发事件
const closeLoginDialog = () => {
  loginPanel.value = false;
};
// 路由跳转后，更新fullPath
router.afterEach((to, _, __) => {
  fullPath.value = to.fullPath;
});
</script>

<style scoped lang="scss">
.login-window {
  position: absolute;
  width: 0;
  height: 0;
}

:deep(.el-dialog) {
  width: 380px;
  border-radius: 5px;
  overflow: hidden;

  .el-dialog__header {
    padding: 0;
    width: 100%;
    height: 108px;
    background: #f5f7fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      &:nth-child(1) {
        font-size: 24px;
        font-family: AlibabaPuHuiTi_2_65_Medium;
        color: #17181a;
        line-height: 32px;
      }

      &:nth-child(2) {
        font-size: 14px;
        font-family: AlibabaPuHuiTi_2_55_Regular;
        color: #666666;
        line-height: 22px;
      }
    }
  }

  .el-dialog__body {
    overflow: hidden;

    .wx-login-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: -35px;
    }
  }
}
</style>
