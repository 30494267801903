import { logoutApi } from '@/apis/auth';
import { useAuthStore } from '@/stores';

const authStore = useAuthStore();

export async function logout(options: { reload?: boolean } = { reload: true }) {
  await logoutApi();
  authStore.reset();
  if (options.reload) {
    window.location.reload();
  }
}
